import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import meter1 from "../assets/img/meter1.svg";
import meter2 from "../assets/img/meter2.svg";
import meter3 from "../assets/img/meter3.svg";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import colorSharp from "../assets/img/color-sharp.png"

export const Skills = () => {
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <section className="skill" id="skills">
        <div className="container">
            <div className="row">
                <div className="col-12">
                    <div className="skill-bx wow zoomIn">
                        <h2>Skills</h2>
                        <p>My skill set encompasses a broad range of technical competencies and a proven ability to deliver innovative solutions.<br /> I excel in software development, focusing on creating robust, scalable applications tailored to meet dynamic business needs.<br /> With a solid foundation in both front-end and back-end technologies, I am committed to mastering the latest industry trends and technologies to drive digital transformation effectively.</p>
                        <Carousel responsive={responsive} infinite={true} className="owl-carousel owl-theme skill-slider">
                            <div className="item">
                                <img src={meter1} alt="Web Development" />
                                <h5>Web Development</h5>
                            </div>
                            <div className="item">
                                <img src={meter2} alt="User Interface Design" />
                                <h5>User Interface Design</h5>
                            </div>
                            <div className="item">
                                <img src={meter3} alt="Javascript and CSS" />
                                <h5>Javascript and CSS</h5>
                            </div>
                            <div className="item">
                                <img src={meter1} alt="Tooling and Libraries" />
                                <h5>Tooling and Libraries</h5>
                            </div>
                        </Carousel>
                    </div>
                </div>
            </div>
        </div>
        <img className="background-image-left" src={colorSharp} alt="Decorative Background" />
    </section>
    )
};