import React from "react";
import { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import headerImg from "../assets/img/header-img.svg";
import { ArrowRightCircle } from 'react-bootstrap-icons';
import 'animate.css';
import TrackVisibility from 'react-on-screen';

export const Banner = () => {
  const [loopNum, setLoopNum] = useState(0);
  const [isDeleting, setIsDeleting] = useState(false);
  const [text, setText] = useState('');
  const [delta, setDelta] = useState(100 - Math.random() * 100);
  const [index, setIndex] = useState(1);
  const toRotate = [ "Aspiring Software Engineer" ];
  const period = 2000;

  useEffect(() => {
    let ticker = setInterval(() => {
      tick();
    }, delta);

    return () => { clearInterval(ticker) };
  }, [text])

  const tick = () => {
    let i = loopNum % toRotate.length;
    let fullText = toRotate[i];
    let updatedText = isDeleting ? fullText.substring(0, text.length - 1) : fullText.substring(0, text.length + 1);

    setText(updatedText);

    if (isDeleting) {
      setDelta(prevDelta => prevDelta / 2);
    }

    if (!isDeleting && updatedText === fullText) {
      setIsDeleting(true);
      setIndex(prevIndex => prevIndex - 1);
      setDelta(period);
    } else if (isDeleting && updatedText === '') {
      setIsDeleting(false);
      setLoopNum(loopNum + 1);
      setIndex(1);
      setDelta(250);
    } else {
      setIndex(prevIndex => prevIndex + 1);
    }
  }

  return (
    <section className="banner" id="home">
      <Container>
        <Row className="aligh-items-center">
          <Col xs={12} md={6} xl={7}>
            <TrackVisibility>
              {({ isVisible }) =>
              <div className={isVisible ? "animate__animated animate__fadeIn" : ""}>
                <span className="tagline">Welcome to my Portfolio</span>
                <h1>{`Hello! My name is Swetha and I am an`} <span className="txt-rotate" dataPeriod="2000" data-rotate='[ "Aspiring Software Engineer"]'><span className="wrap">{text}</span></span></h1>
                  <p>My journey into the world of technology began in 2020, fueled by curiosity and a passion for understanding the profound impact software has on our daily lives. Since then, I have dedicated myself to an immersive learning experience, mastering key programming languages and software development methodologies. My portfolio is a reflection of this journey, featuring a diverse array of projects from robust web applications that address real-world challenges to innovative software solutions aimed at enhancing user experiences. Each project not only showcases my technical skills but also my ability to think critically and collaborate effectively within teams.

As I continue to navigate the vibrant path of technology, I am eager to contribute meaningfully to the industry and achieve new heights in software development. </p>
                <a href={`${process.env.PUBLIC_URL}/Swetha_CV___SWE.pdf`} download="Swetha_CV___SWE.pdf">
                <button onClick={() => console.log('connect')}>Download My CV Here<ArrowRightCircle size={25} /></button>
                </a>
              </div>}
            </TrackVisibility>
          </Col>
          <Col xs={12} md={6} xl={5}>
            <TrackVisibility>
              {({ isVisible }) =>
                <div className={isVisible ? "animate__animated animate__easeIn" : ""}>
                  <img src={headerImg} alt="Header Img"/>
                </div>}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
    </section>
    )
};
